@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-menu.ant-menu-light.customMenu {
  background-color: transparent;
  border: none;
}

.ant-menu.ant-menu-light .ant-menu-item.customMenuItem {
  padding-bottom: 19px;
}

.demoButton{
  border-radius: 4px;
  background-color: #1A94F2;
  border: none;
  color: white;
  width: 160px;
  height: 47px;
  box-shadow: 1px 3px 8px #00000024;
  font-family: roboto;
}

.demoButton:hover{
  box-shadow: 0 4px 19px -5px #949494;
  border-radius: 4px;
  background-color: #1A94F2;
  border: none;
  color: white;
  width: 160px;
  height: 47px;
  font-family: roboto;
  position: relative;
  margin-top: -2px;
} 

.productButton{
  border-radius: 4px;
  background-color: #E8F3FC;
  border:solid 1px #1A94F2;
  color: #1A94F2;
  width: 160px;
  height: 47px;
  box-shadow: 1px 3px 8px #00000024;
  font-family: roboto;
}

.productButton:hover{
  box-shadow: 0 4px 19px -5px #949494;
  border-radius: 4px;
  background-color: #E8F3FC;
  border:solid 1px #1A94F2;
  color: #1A94F2;
  width: 160px;
  height: 47px;
  font-family: roboto;
  position: relative;
  margin-top: -2px;
} 
 .dot{
  border:solid 1px #1A94F2;
 }

.contentStyle
{
    margin: auto;
    max-width: 1200px;
    padding-left: 20px;
    padding-right: 20px;
}

.footerLink
{
  font-size: 16px;
  color: #363636;
  font-family: roboto;
  cursor: pointer;
}

.footerLink:hover
{
  font-size: 16px;
  color: #3F8DC9;
  font-family: roboto;
  cursor: pointer;
}

.viewPage
{
  text-align: left;
  color: #505050;
  font-size: 16px;
  font-family: roboto;
  font-weight: 600;
  cursor: pointer;
}
.viewPage:hover
{
  text-align: left;
  color: #3F8DC9;
  font-size: 16px;
  font-family: roboto;
  font-weight: 600;
  cursor: pointer;
  margin-right: 5px;
}

.getDemo
{
  border-radius: 4px;
  background-color:#E8F3FC;
  border: solid 1px #1A94F2;
  color: #1A94F2;
  width: 160px;
  height: 47px;
  box-shadow: 1px 3px 8px #00000024;
}

.getDemo:hover
{
  border-radius: 4px;
  background-color:#E8F3FC;
  border: solid 1px #1A94F2;
  color: #1A94F2;
  width: 160px;
  height: 47px;
  box-shadow: 0 4px 19px -5px #949494;
  position: relative;
  margin-top: -2px;
}

.ant-popover-inner-content{
  padding: 0;
}

.ant-menu-vertical .ant-menu-item 
    {
        white-space: normal;
    }

.menuHover:hover {
  background-color:#F8F8F8 ;
}